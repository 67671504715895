<template>
  <!--compatibility 男女合盘-->
  <div class="compatibility">
    <div class="nav">
      <h2>
        <span class="back" v-on:click="backToHome()">&lt;&nbsp;&nbsp;返回</span>
      </h2>
    </div>
    <article class="compatibility-wrap">
      <!--cover 产品主视觉-->
      <div></div>
      <section class="cover cover-preview"></section>
      <!--/cover 产品主视觉-->
      <!--report 合盘报告-->
      <article class="report">
        <!--user 使用者资料-->
        <section class="user">
          <section class="con-wrap user-wrap">
            <header class="user-hd">
              <div class="user-data">
                <p class="user-name">
                  您的资料：{{ simpleResult.name }} /
                  {{ simpleResult.gender == 1 ? '男' : '女' }}
                </p>
                <p class="user-subtit">
                  阳历<span class="num">{{
                    getMoment(simpleResult.birthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(simpleResult.birthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(simpleResult.birthdayStr).date()
                  }}</span
                  >日<span class="num">{{
                    getSolarHour(getMoment(simpleResult.birthdayStr).hour())
                  }}</span>
                </p>
                <p class="user-subtit">
                  农历<span class="num">{{
                    getMoment(simpleResult.lunarBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(simpleResult.lunarBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(simpleResult.lunarBirthdayStr).date()
                  }}</span
                  >日<span>{{
                    getLunarHour(
                      getMoment(simpleResult.lunarBirthdayStr).hour()
                    )
                  }}</span
                  >时
                </p>
              </div>
              <hr />
              <div class="user-data">
                <p class="user-name">
                  对方资料：{{ simpleResult.targetName }} /
                  {{ simpleResult.targetGender == 1 ? '男' : '女' }}
                </p>
                <p class="user-subtit">
                  阳历<span class="num">{{
                    getMoment(simpleResult.targetBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(simpleResult.targetBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(simpleResult.targetBirthdayStr).date()
                  }}</span
                  >日
                </p>
                <p class="user-subtit">
                  农历<span class="num">{{
                    getMoment(simpleResult.targetLunarBirthdayStr).year()
                  }}</span
                  >年<span class="num">{{
                    getMoment(simpleResult.targetLunarBirthdayStr).month() + 1
                  }}</span
                  >月<span class="num">{{
                    getMoment(simpleResult.targetLunarBirthdayStr).date()
                  }}</span
                  >日
                </p>
              </div>
            </header>
            <!--unlock 立刻解锁-->
            <div class="unlock">
              <a href="#" @click="submit()" class="btn-action btn-payment"
                >立刻解锁看分析</a
              >
              <p class="unlock-price">
                128 元<br
                  class="lg-hide md-hide sm-hide xs-hide xxs-show"
                /><span class="price"
                  >限时优惠 <em> {{ price }} </em>元</span
                >
              </p>
              <p class="unlock-countdown">
                距离优惠结束<br
                  class="lg-hide md-hide sm-hide xs-hide xxs-show"
                />
                <span class="countdown countdown-itspan"
                  ><span id="t_h"></span>： <span id="t_m"></span>：
                  <span id="t_s"></span
                ></span>
              </p>
            </div>
            <!--/unlock 立刻解锁-->
            <h2 class="user-tit">你的心仪对象是怎样的人？</h2>
            <div class="user-con">
              <p>
                {{ simpleResult.q1.desc[0] }}
              </p>
              <div class="blur blur-wrapper">
                {{ simpleResult.q1.desc.join('') }}
              </div>
            </div>
          </section>
        </section>
        <!--/user 使用者资料-->
        <!--intro 产品介绍-->
        <section class="intro">
          <div class="con-wrap intro-wrap">
            <h2 class="blk-tit">
              <span class="tit-main">本测算提供以下分析</span>
            </h2>
            <ol class="list-intro">
              <li>
                <figure class="intro-icon _icon1"></figure>
                <p class="intro-txt">你的感情观如何？</p>
              </li>
              <li>
                <figure class="intro-icon _icon2"></figure>
                <p class="intro-txt">你在TA眼中是怎样的人？</p>
              </li>
              <li>
                <figure class="intro-icon _icon3"></figure>
                <p class="intro-txt">
                  TA是怎样的人？<br />个性特点、喜好跟雷区
                </p>
              </li>
            </ol>
            <section class="intro-items">
              <section class="intro-know">
                <h3><span>跟TA相处，你该知道的</span></h3>
                <ul>
                  <li>
                    TA喜欢的事情—<br
                      class="lg-hide md-hide sm-show"
                    />积极加分的事
                  </li>
                  <li>
                    TA的地雷区—<br
                      class="lg-hide md-hide sm-show"
                    />应避免踩的雷
                  </li>
                  <li>
                    你们交往的优势
                  </li>
                  <li>
                    你们交往的劣势
                  </li>
                </ul>
              </section>
              <section class="intro-next">
                <h3><span>下一步到底会如何？</span></h3>
                <ul>
                  <li>TA喜欢你吗？</li>
                  <li>你们在一起的机率多高？</li>
                  <li>你们交往相处的最佳年份</li>
                  <li>你们容易摩擦不顺的年份</li>
                </ul>
              </section>
            </section>
          </div>
        </section>
        <!--/intro 产品介绍-->
        <section class="con-wrap report-wrap">
          <!--result 测算结果-->
          <section class="result">
            <h2 class="blk-tit">关于本测算结果</h2>
            <p>
              这份合盘分析中，运用“中华第一神术”——紫微斗数为您进行预测。紫微斗数为古代帝王之术，在古代专供皇家钦天监使用；在现代，与社会环境、恋爱社交的近代意蕴结合，进而在中国神秘预测文化中卓立而出。
            </p>
            <p>
              在报告中，也许会有一些看似是一般常理的建议（如注重沟通、心境调整）等，这些却是依你的命盘及合盘运行所给的针对性分析，是你在相处中最需要注意的环节。请勿因它看似常理而轻忽了这些讯息对你的影响力及重要性。
            </p>
            <p>接下来，我们就来看关于你和TA的缘分如何吧！</p>
          </section>
          <!--/result 测算结果-->
          <!--you 关于你-->
          <section class="you">
            <h3 class="blk-tit"><span>你的感情观如何</span></h3>
            <section class="vision _you">
              <figure class="vision-cards">
                <div>
                  <img
                    v-for="(name, index) in simpleResult.q1.pic"
                    :key="index"
                    :src="'/image/star/' + name + '.jpeg'"
                  />
                  <!-- <img src="/image/ill_card_default.png" /> -->
                </div>
                <figcaption class="cards-name">
                  <span>
                    {{ simpleResult.q1.xingZuo[0] }}
                  </span>
                </figcaption>
              </figure>
              <div class="vision-txt">
                <p>
                  {{ simpleResult.q1.desc[0] }}
                </p>
                <div class="blur blur-wrapper">
                  {{ simpleResult.q1.desc.join('') }}
                </div>
              </div>
            </section>
            <hr />
            <h3 class="blk-tit">
              <span
                >你在TA眼中<br
                  class="lg-hide md-hide sm-hide xs-show"
                />是怎样的⼈</span
              >
            </h3>
            <section class="vision _other">
              <figure class="vision-cards">
                <div>
                  <img
                    v-for="(name, index) in simpleResult.q2.pic"
                    :key="index"
                    :src="'/image/star/' + name + '.jpeg'"
                  />
                </div>
                <figcaption class="cards-name">
                  <span>
                    {{ simpleResult.q2.pic.join('') }}
                  </span>
                </figcaption>
              </figure>
              <div class="vision-txt">
                <p>
                  {{ simpleResult.q2.desc[0] }}
                </p>
                <div class="blur blur-wrapper">
                  {{ simpleResult.q2.desc.join('') }}
                </div>
              </div>
            </section>
          </section>
          <!--/you 关于你-->
          <!--other 关于对方-->
          <section class="other">
            <section class="other-about">
              <h3 class="blk-tit"><span>关于TA</span></h3>
              <div class="about-intro">
                <h4>TA是怎样的⼈？个性、特点跟喜好</h4>
                <p class="blur">
                  <span
                    v-for="(item, index) in simpleResult.q3.desc"
                    :key="index"
                    >{{ item }}<br
                  /></span>
                </p>
              </div>
            </section>
            <hr />
            <!--other-beware 注意事项-->
            <section class="other-beware">
              <div class="beware-intro">
                <h3 class="blk-tit">
                  <span
                    >跟TA相处，<br
                      class="lg-hide md-hide sm-hide xs-show"
                    />你该知道的</span
                  >
                </h3>
                <p>
                  在这一章节之中，您需要着重查看劣势与雷区，这将是你这段关系成败的重要因素。切勿因为优势而欢欣鼓舞，激动过头，却忽略了雷区与劣势的重要！
                </p>
                <p>接下来，我们就来揭晓你们的缘分细节吧！</p>
              </div>
              <ol class="list-beware">
                <li class="score-add">
                  <div>
                    <header>
                      <h4>TA喜欢的事情<br />相处时积极加分的事</h4>
                    </header>
                    <p class="blur">
                      <span
                        v-for="(item, index) in simpleResult.q4.desc"
                        :key="index"
                        >{{ item }}<br
                      /></span>
                    </p>
                  </div>
                </li>
                <li class="score-deduct">
                  <div>
                    <header>
                      <h4>TA的地雷区<br />相处时应避免踩的雷</h4>
                    </header>
                    <p class="blur">
                      <span
                        v-for="(item, index) in simpleResult.q5.desc"
                        :key="index"
                        >{{ item }}<br
                      /></span>
                    </p>
                  </div>
                </li>
                <li class="together-add">
                  <div>
                    <header>
                      <h4>你们交往的优势</h4>
                    </header>
                    <p class="blur">
                      <span
                        v-for="(item, index) in simpleResult.q6.desc"
                        :key="index"
                        >{{ item }}<br
                      /></span>
                    </p>
                  </div>
                </li>
                <li class="together-deduct">
                  <div>
                    <header>
                      <h4>你们交往的劣势</h4>
                    </header>
                    <p class="blur">
                      <span
                        v-for="(item, index) in simpleResult.q7.desc"
                        :key="index"
                        >{{ item }}<br
                      /></span>
                    </p>
                  </div>
                </li>
              </ol>
            </section>
            <!--/other-beware 注意事项-->
          </section>
          <!--/other 关于对方-->
          <!--how 下一步如何-->
          <section class="how">
            <h3 class="blk-tit">
              <span
                >下⼀步<br
                  class="lg-hide md-hide sm-hide xs-show"
                />到底会如何？</span
              >
            </h3>
            <section class="how-summary">
              <figure class="summary-chart">
                <img src="/image/ill_chart_ability_question.png" />
                <!-- <div id="fleetData" style="height:250px"></div> -->
              </figure>
              <div class="summary-txt">
                <h4>TA喜欢你吗？</h4>
                <p class="blur">
                  <span
                    v-for="(item, index) in simpleResult.q8.desc"
                    :key="index"
                    >{{ item }}<br
                  /></span>
                </p>
              </div>
            </section>
            <hr />
            <section class="how-chances">
              <figure class="chances-percent">
                <img src="/image/ill_percent.png" />
                <!--<figcaption class="percent-score"><span><em>?</em>%</span></figcaption>-->
              </figure>
              <div class="chances-txt">
                <h4>你们在⼀起的分数有多高？</h4>
                <p class="blur">
                  {{ simpleResult.q9.desc }}
                </p>
              </div>
            </section>
            <section class="how-years">
              <section class="years-best">
                <h4><span>你们交往相处的最佳年份</span></h4>
                <!--<div><p>2022、2023<br />2024、2025</p></div>-->
              </section>
              <section class="years-bad">
                <h4><span>你们容易摩擦不顺的年份</span></h4>
                <!--<div><p>2028</p></div>-->
              </section>
            </section>
          </section>
          <!--/how 下一步如何-->
          <!--unlock 立刻解锁-->
          <section class="unlock _footer">
            <div class="con-wrap">
              <a href="#" @click="submit()" class="btn-action btn-payment"
                >立刻解锁看分析</a
              >
              <p class="unlock-price _color">
                128 元<br
                  class="lg-hide md-hide sm-hide xs-hide xxs-show"
                /><span class="price"
                  >限时优惠 <em> {{ price }} </em>元</span
                >
              </p>
            </div>
          </section>
          <!--/unlock 立刻解锁-->
        </section>
      </article>
      <!--/report 合盘报告-->
    </article>
    <div class="line-scale" v-show="isLoading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!--/compatibility 男女合盘-->
</template>

<script>
import axios from 'axios'
import store from '@/store'
import moment from 'moment-timezone'

import { mapState } from 'vuex'
import { Base64 } from 'js-base64'
import { SHI_CHEN } from '@/constants/shi-chen-list'
import { FORTUNE_ACTIONS } from '@/store/modules/fortune-actions-types'

import share from '@/share'

export default {
  name: 'Preview',
  data() {
    return { simpleResult: null, paymentLoadingCount: 0 }
  },
  beforeRouteEnter(to, from, next) {
    if (Object.entries(store.state.fortune.request).length === 0) {
      next({ name: 'Home' })
    } else {
      next()
    }
  },
  mounted: function() {
    localStorage.setItem(
      'previewRequest',
      JSON.stringify(this.$store.state.fortune.request)
    )
    document.documentElement.scrollTop = 0
    let payload = {}
    payload.ttxid = this.$store.state.user.ttxid
    payload.timestamp = this.$store.state.user.timestamp
    payload.token = this.$store.state.user.token
    this.$store.dispatch('fortune/getSimpleResult', payload).then(
      res => {
        countdown()
        this.simpleResult = res
      },
      err => {
        alert('对不起,无法辨识')
        this.$router.push({ name: 'Home' })
      }
    )
    try {
      TtxTrack.track('he-pan', 'FORTUNE_SIMPLERESULT')
    } catch (e) {
      // console.log(e)
    }
  },
  methods: {
    backToHome: function() {
      this.$router.push({ name: 'Home' })
    },
    linkToService: function() {
      this.$router.push({ name: 'Service' })
    },
    submit: async function() {
      let payload = {}
      payload.ttxid = this.$store.state.user.ttxid
      payload.timestamp = this.$store.state.user.timestamp
      payload.token = this.$store.state.user.token

      this.paymentLoadingCount++
      try {
        if (share.isWeixin()) {
          let chargeResp = await axios.post(
            'https://api.taotaoxi.cn/payment/wechat/jsapi/charge?ttxid=' +
              payload.ttxid +
              '&timestamp=' +
              payload.timestamp +
              '&token=' +
              payload.token,
            {
              product: [
                { id: this.$store.state.fortune.data.weChatId, quantity: 1 }
              ],
              appId: 'wxa2acc77c8368a9dc',
              subject: '合盘的'
            }
          )
          payload.orderNo = chargeResp.data.orderNo
          try {
            TtxTrack.track('he-pan', 'FORTUNE_PAY')
          } catch (e) {
            // console.log(e)
          }
          this.$store.dispatch('fortune/postQuizData', payload).then(res => {
            // jump to wx jsapi payment
            let successUrl = Base64.encodeURI(
              'http://' +
                location.host +
                '/result/' +
                chargeResp.data.orderNo +
                '?channel=' +
                this.channel
            )
            let failUrl = Base64.encodeURI(
              location.href + '?channel=' + this.channel
            )
            let charge = Base64.encodeURI(
              unescape(encodeURIComponent(JSON.stringify(chargeResp.data)))
            )
            location.replace(
              'https://shared-resource.taotaoxi.net/wx-pub-pay/?charge=' +
                charge +
                '&success_next=' +
                successUrl +
                '&fail_next=' +
                failUrl
            )
          })
        } else {
          let chargeResp = await axios.post(
            'https://api.taotaoxi.cn/payment/wechat/h5/charge?ttxid=' +
              payload.ttxid +
              '&timestamp=' +
              payload.timestamp +
              '&token=' +
              payload.token,
            {
              product: [
                { id: this.$store.state.fortune.data.weChatH5Id, quantity: 1 }
              ],
              appId: 'wxa2acc77c8368a9dc',
              subject: '合盘'
            }
          )
          payload.orderNo = chargeResp.data.orderNo
          try {
            TtxTrack.track('he-pan', 'FORTUNE_PAY')
          } catch (e) {
            // console.log(e)
          }
          this.$store.dispatch('fortune/postQuizData', payload).then(res => {
            // jump to wx h5 payment
            let redirectUrl =
              'https://' +
              location.host +
              '/result/' +
              chargeResp.data.orderNo +
              '?channel=' +
              this.channel
            location.replace(
              chargeResp.data.link +
                '&redirect_url=' +
                encodeURIComponent(redirectUrl)
            )
          })
        }
      } catch (err) {
      } finally {
        this.paymentLoadingCount--
      }
    },
    getMoment: function(timeString) {
      return moment.tz(timeString, 'YYYYMMDDHH', 'Etc/GMT-8')
    },
    getSolarHour: function(hour) {
      let str
      if (0 === hour || 23 === hour) {
        str = hour + ':00 ~ ' + hour + ':59'
      } else {
        str = hour + ':00 ~ ' + (hour + 1) + ':59'
      }
      return str
    },
    getLunarHour: function(hour) {
      if (hour !== 0 && hour % 2 === 0) {
        hour--
      }
      return SHI_CHEN[hour]
    }
  },
  computed: {
    ...mapState('fortune', {
      channel: 'channel',
      count: 'count',
      isLoading: function(state) {
        return state.loadingCount + this.paymentLoadingCount > 0
      },
      price: function(state) {
        if (share.isWeixin()) {
          return state.data.price
        } else {
          return state.data.priceH5
        }
      }
    })
  }
}

let countdown = function() {
  let EndTime = new Date().getTime() + 7200000

  function GetRTime() {
    var NowTime = new Date()
    var t = EndTime - NowTime.getTime()
    var h = 0,
      m = 0,
      s = 0
    if (t >= 0) {
      h = Math.floor((t / 1000 / 60 / 60) % 24)
      m = Math.floor((t / 1000 / 60) % 60)
      s = Math.floor((t / 1000) % 60)
    }
    try {
      document.getElementById('t_h').innerHTML = h
      document.getElementById('t_m').innerHTML = m
      document.getElementById('t_s').innerHTML = s
    } catch (ex) {
      clearInterval(GetRTimeInter)
    }
  }

  let GetRTimeInter = setInterval(GetRTime, 200)
}
</script>

<style src="@/assets/css/compatibility.css"></style>
<style>
.nav {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.back {
  position: absolute;
  left: 3%;
}
</style>
